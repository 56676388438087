export enum STEP_NAMES {
  SLOTS = "slots",
  PRODUCTS = "products",
  PRODUCTS_BEFORE_STORE = "productsBeforeStore",
  PROFILE = "profile",
  VIRTUAL_PRODUCTS_BEFORE_STORE = "virtualProductsBeforeStore",
  LIVE_CHAT_PRODUCTS = "live",
  ADDONS = "addons",
  ADVISORS = "advisors",
  VENUES = "venues",
  QUEUES = "queues",
  RESCHEDULE = "reschedule",
  RESERVATIONS = "reservations",
  DETAILS = "details",
  CONFIRMATION = "confirmation",
  BOOKING = "booking",
  CANCELLATION = "cancellation",
  TYPE = "type",
  ERROR = "error",
}

export type StepNames = STEP_NAMES | "";
