import Router, { NextRouter } from "next/router";
import { CardBookingValidTypes } from "../components/bookingTypes/types";
import { BookingType, JourneyFromType, StepNames, STEP_NAMES } from "../shared";
import { getBWID } from "./getBWID";
import { getFirstPageFromRouter } from "./getFirstPageFromRouter";
import { QUESTION_ANSWERS_QUERY_PARAM_KEY } from "./getQuestionsFromQueryParams";

const queryParamsToPersist: string[] = [
  "_ga",
  "externalId",
  "orderNumber",
  "embedded",
  "lang",
  "from",
  "by",
  "location",
  QUESTION_ANSWERS_QUERY_PARAM_KEY,
  "accessToken",
  "followUpId",
  "firstName",
  "lastName",
  "customerDetails",
];

const isTypeFirstPage = () => getFirstPageFromRouter() === STEP_NAMES.TYPE;

const buildChooseTypeUrl = () =>
  isTypeFirstPage() ? `/${STEP_NAMES.TYPE}` : "";

const buildVirtualProductsBaseUrl = () => {
  const chooseTypeInUrl = buildChooseTypeUrl();
  return `${chooseTypeInUrl}/virtual/products`;
};

const getQueryParamsWithFrom = (from: string, queryParams: string) => {
  if (queryParams.length <= 0) {
    return `?from=${from}`;
  }

  const fromSrc = getQueryStringValue("from");
  if (fromSrc) {
    return queryParams.replace(fromSrc, from);
  } else {
    return `${queryParams}&from=${from}`;
  }
};

export const getQueryStringValue = (key: string) =>
  typeof window === "undefined"
    ? ""
    : decodeURIComponent(
        window.location.search.replace(
          new RegExp(
            "^(?:.*[&\\?]" +
              encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
              "(?:\\=([^&]*))?)?.*$",
            "i"
          ),
          "$1"
        )
      );

export const persistQueryParams = (
  router?: any,
  removeQueries: string[] = []
) => {
  const query = router ? router.query : undefined;
  if (queryParamsToPersist.length > 0) {
    let queryParams = "";
    queryParamsToPersist.forEach((key: string) => {
      const value = (query && query[key]) || getQueryStringValue(key);
      if (value) {
        if (!removeQueries.includes(key)) {
          queryParams += `${queryParams === "" ? "?" : "&"}${key}=${value}`;
        }
      }
    });
    return queryParams;
  }

  return "";
};

const getQueryParamsWithFromType = (removeQueries?: string[]) => {
  const persistedQueryParams = persistQueryParams(Router, removeQueries);
  const firstPage = getFirstPageFromRouter();

  const isFromVirtualProductsBeforeStep =
    isVirtualProductsBeforeStoreStep(Router, firstPage) &&
    buildVirtualProductsBaseUrl();

  const isFromProductsBeforeStep =
    isProductsBeforeStoreStep(Router, firstPage) &&
    `/${STEP_NAMES.TYPE}/${STEP_NAMES.PRODUCTS}`;

  const fromParamValue =
    isFromVirtualProductsBeforeStep ||
    isFromProductsBeforeStep ||
    `/${STEP_NAMES.TYPE}`;

  return firstPage === STEP_NAMES.TYPE
    ? getQueryParamsWithFrom(fromParamValue, persistedQueryParams)
    : persistedQueryParams;
};

export const getBookingTypeFromUrl = (
  router: NextRouter,
  firstPage: StepNames,
  bookingType?: BookingType
): CardBookingValidTypes | undefined => {
  if (firstPage !== STEP_NAMES.TYPE) {
    return;
  }
  if (bookingType && router.query.from?.includes("type")) {
    const isInStoreOrVirtual =
      bookingType === "IN_PERSON" || bookingType === "VIRTUAL";
    // TODO: validate how from param works with /live and /virtual if we refresh page or in netlify
    return isInStoreOrVirtual ? "inStoreOrVirtual" : undefined;
  }

  const pathname = router.pathname.split("/");
  const from = router.query.from;

  if (pathname?.includes("live") || from?.includes("live")) {
    return "liveChat";
  }

  if (pathname?.includes("virtual") || from?.includes("virtual")) {
    return "virtualAggregated";
  }

  if (
    pathname?.includes("venues") ||
    (pathname?.includes("products") && !pathname?.includes("virtual")) ||
    (from?.includes("products") && !from?.includes("virtual"))
  ) {
    return "inStoreOrVirtual";
  }

  return undefined;
};

export const isVirtualProductsBeforeStoreStep = (
  router: NextRouter,
  firstPage: StepNames
) =>
  (router.pathname.split("/")?.includes("virtual") ||
    router.query.from?.includes("virtual")) &&
  (firstPage === STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE ||
    firstPage === STEP_NAMES.TYPE);

export const isLiveProductsStep = (router: NextRouter, firstPage: StepNames) =>
  (router.pathname.split("/")?.includes("live") ||
    router.query.from?.includes("live")) &&
  (firstPage === STEP_NAMES.LIVE_CHAT_PRODUCTS ||
    firstPage === STEP_NAMES.TYPE);

export const isProductsBeforeStoreStep = (
  router: NextRouter,
  firstPage: StepNames
) => {
  const path = router.pathname.split("/");
  const from = router.query.from;

  return (
    ((path?.includes("products") &&
      !path?.includes("virtual") &&
      !path?.includes("venues")) ||
      (from?.includes("products") && !from?.includes("virtual"))) &&
    (firstPage === STEP_NAMES.PRODUCTS_BEFORE_STORE ||
      firstPage === STEP_NAMES.TYPE)
  );
};

export const isLiveJourney = (router: NextRouter, firstPage: StepNames) => {
  const path = router.pathname.split("/");
  const from = router.query.from;

  return (
    (path?.includes("live") || from?.includes("live")) &&
    (firstPage === STEP_NAMES.LIVE_CHAT_PRODUCTS ||
      firstPage === STEP_NAMES.TYPE)
  );
};

const goToGenericRoute = (
  url: string,
  withParams?: boolean,
  replace?: boolean
) => {
  const action = replace ? Router.replace : Router.push;
  const queryParams = withParams ? persistQueryParams(Router) : "";

  action(`/[BWID]${url}${queryParams}`, `/${getBWID()}${url}${queryParams}`, {
    shallow: true,
  });
};

const goToChooseBookingType = (replace?: boolean) => {
  const action = replace ? Router.replace : Router.push;

  action(`/[BWID]/${STEP_NAMES.TYPE}`, `/${getBWID()}/${STEP_NAMES.TYPE}`, {
    shallow: true,
  });
};

const goToVenues = (replace?: boolean, removeQueries: string[] = []) => {
  const action = replace ? Router.replace : Router.push;

  const queryParams = persistQueryParams(Router, removeQueries);
  const chooseTypeInUrl = buildChooseTypeUrl();

  action(
    `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.VENUES}${queryParams}`,
    `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.VENUES}${queryParams}`,
    {
      shallow: true,
    }
  );
};

const goToVenuesProductsBeforeStore = (
  productId?: string,
  replace?: boolean
) => {
  if (!productId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;
  const chooseTypeInUrl = buildChooseTypeUrl();

  const queryParams = getQueryParamsWithFrom(
    `${chooseTypeInUrl}/${STEP_NAMES.PRODUCTS}`,
    persistQueryParams(Router)
  );

  action(
    `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.PRODUCTS}/[productId]/${STEP_NAMES.VENUES}${queryParams}`,
    `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.PRODUCTS}/${productId}/${
      STEP_NAMES.VENUES
    }${queryParams}`,
    { shallow: true }
  );
};

const goToQueues = (venueId?: string, replace?: boolean) => {
  if (!venueId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;

  const queryParams = persistQueryParams(Router);

  action(
    `/[BWID]/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.QUEUES}${queryParams}`,
    `/${getBWID()}/${STEP_NAMES.VENUES}/${venueId}/${
      STEP_NAMES.QUEUES
    }${queryParams}`,
    { shallow: true }
  );
};

const goToProducts = (
  venueId?: string,
  queueId?: string,
  replace?: boolean
) => {
  if (!venueId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;

  const queryParams = persistQueryParams(Router);
  const chooseTypeInUrl = buildChooseTypeUrl();

  if (queueId) {
    action(
      `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.QUEUES}/[queueId]/${STEP_NAMES.PRODUCTS}${queryParams}`,
      `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.VENUES}/${venueId}/${
        STEP_NAMES.QUEUES
      }/${queueId}/${STEP_NAMES.PRODUCTS}${queryParams}`,
      { shallow: true }
    );
  } else {
    action(
      `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.PRODUCTS}${queryParams}`,
      `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.VENUES}/${venueId}/${
        STEP_NAMES.PRODUCTS
      }${queryParams}`,
      { shallow: true }
    );
  }
};

const goToAddOns = (
  venueId?: string,
  queueId?: string,
  productId?: string,
  replace?: boolean
) => {
  if (!venueId || !productId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;
  const chooseTypeInUrl = buildChooseTypeUrl();

  const queryParams = persistQueryParams(Router);

  if (queueId) {
    action(
      `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.QUEUES}/[queueId]/${STEP_NAMES.PRODUCTS}/[productId]/${STEP_NAMES.ADDONS}${queryParams}`,
      `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.VENUES}/${venueId}/${
        STEP_NAMES.QUEUES
      }/${queueId}/${STEP_NAMES.PRODUCTS}/${productId}/${
        STEP_NAMES.ADDONS
      }${queryParams}`,
      { shallow: true }
    );
  } else {
    action(
      `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.PRODUCTS}/[productId]/${STEP_NAMES.ADDONS}${queryParams}`,
      `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.VENUES}/${venueId}/${
        STEP_NAMES.PRODUCTS
      }/${productId}/${STEP_NAMES.ADDONS}${queryParams}`,
      { shallow: true }
    );
  }
};

const goToAddOnsBeforeStore = (productId?: string, replace?: boolean) => {
  if (!productId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;
  const chooseTypeInUrl = buildChooseTypeUrl();

  const queryParams = getQueryParamsWithFrom(
    `${chooseTypeInUrl}/${STEP_NAMES.PRODUCTS}`,
    persistQueryParams(Router)
  );

  action(
    `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.PRODUCTS}/[productId]/${STEP_NAMES.ADDONS}${queryParams}`,
    `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.PRODUCTS}/${productId}/${
      STEP_NAMES.ADDONS
    }${queryParams}`,
    { shallow: true }
  );
};

const goToVirtualAddOnsBeforeStore = (
  productId?: string,
  replace?: boolean
) => {
  if (!productId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;
  const virtualProductsBaseUrl = buildVirtualProductsBaseUrl();
  const queryParams = getQueryParamsWithFrom(
    virtualProductsBaseUrl,
    persistQueryParams(Router)
  );

  action(
    `/[BWID]${virtualProductsBaseUrl}/[productId]/${STEP_NAMES.ADDONS}${queryParams}`,
    `/${getBWID()}${virtualProductsBaseUrl}/${productId}/${
      STEP_NAMES.ADDONS
    }${queryParams}`,
    { shallow: true }
  );
};

const goToAdvisors = (
  bookingId?: string,
  venueId?: string,
  queueId?: string,
  productId?: string,
  replace?: boolean
) => {
  if (!bookingId && (!venueId || !productId)) {
    return;
  }

  const action = replace ? Router.replace : Router.push;
  const chooseTypeInUrl = buildChooseTypeUrl();
  const queryParams = persistQueryParams(Router);
  const queryParamsWithFrom = getQueryParamsWithFromType();

  if (bookingId) {
    action(
      `/[BWID]/${STEP_NAMES.BOOKING}/[bookingId]/${STEP_NAMES.RESCHEDULE}/${STEP_NAMES.ADVISORS}${queryParamsWithFrom}`,
      `/${getBWID()}/${STEP_NAMES.BOOKING}/${bookingId}/${
        STEP_NAMES.RESCHEDULE
      }/${STEP_NAMES.ADVISORS}${queryParamsWithFrom}`,
      { shallow: true }
    );
  } else if (queueId) {
    action(
      `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.QUEUES}/[queueId]/${STEP_NAMES.PRODUCTS}/[productId]/${STEP_NAMES.ADVISORS}${queryParams}`,
      `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.VENUES}/${venueId}/${
        STEP_NAMES.QUEUES
      }/${queueId}/${STEP_NAMES.PRODUCTS}/${productId}/${
        STEP_NAMES.ADVISORS
      }${queryParams}`,
      { shallow: true }
    );
  } else {
    action(
      `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.PRODUCTS}/[productId]/${STEP_NAMES.ADVISORS}${queryParams}`,
      `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.VENUES}/${venueId}/${
        STEP_NAMES.PRODUCTS
      }/${productId}/${STEP_NAMES.ADVISORS}${queryParams}`,
      { shallow: true }
    );
  }
};

const goToReschedule = (
  bookingId?: string,
  replace?: boolean,
  journeyFrom?: string
) => {
  const action = replace ? Router.replace : Router.push;
  if (!Router.query.from) {
    Router.query.from = journeyFrom;
  }
  const queryParams = getQueryParamsWithFromType();

  action(
    `/[BWID]/${STEP_NAMES.BOOKING}/[bookingId]/${STEP_NAMES.RESCHEDULE}${queryParams}`,
    `/${getBWID()}/${STEP_NAMES.BOOKING}/${bookingId}/${
      STEP_NAMES.RESCHEDULE
    }${queryParams}`,
    {
      shallow: true,
    }
  );
};

const goToSlots = (
  bookingId?: string,
  venueId?: string,
  queueId?: string,
  productId?: string,
  replace?: boolean
) => {
  if (bookingId) {
    goToReschedule(bookingId, replace);
  }

  if (bookingId || !venueId || !productId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;
  const chooseTypeInUrl = buildChooseTypeUrl();

  const queryParams = persistQueryParams(Router);

  if (queueId) {
    action(
      `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.QUEUES}/[queueId]/${STEP_NAMES.PRODUCTS}/[productId]/${STEP_NAMES.SLOTS}${queryParams}`,
      `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.VENUES}/${venueId}/${
        STEP_NAMES.QUEUES
      }/${queueId}/${STEP_NAMES.PRODUCTS}/${productId}/${
        STEP_NAMES.SLOTS
      }${queryParams}`,
      { shallow: true }
    );
  } else {
    action(
      `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.PRODUCTS}/[productId]/${STEP_NAMES.SLOTS}${queryParams}`,
      `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.VENUES}/${venueId}/${
        STEP_NAMES.PRODUCTS
      }/${productId}/${STEP_NAMES.SLOTS}${queryParams}`,
      { shallow: true }
    );
  }
};

const goToVirtualSlotsBeforeStore = (productId?: string, replace?: boolean) => {
  if (!productId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;
  const virtualProductsBaseUrl = buildVirtualProductsBaseUrl();
  const queryParams = getQueryParamsWithFrom(
    virtualProductsBaseUrl,
    persistQueryParams(Router)
  );

  action(
    `/[BWID]${virtualProductsBaseUrl}/[productId]/${STEP_NAMES.SLOTS}${queryParams}`,
    `/${getBWID()}${virtualProductsBaseUrl}/${productId}/${
      STEP_NAMES.SLOTS
    }${queryParams}`,
    { shallow: true }
  );
};

const goToDetails = (
  bookingId?: string,
  reservationId?: string,
  replace?: boolean
) => {
  if (!reservationId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;

  const queryParams = getQueryParamsWithFromType();

  if (bookingId) {
    action(
      `/[BWID]/${STEP_NAMES.BOOKING}/[bookingId]/${STEP_NAMES.RESCHEDULE}/[reservationId]/${STEP_NAMES.DETAILS}${queryParams}`,
      `/${getBWID()}/${STEP_NAMES.BOOKING}/${bookingId}/${
        STEP_NAMES.RESCHEDULE
      }/${reservationId}/${STEP_NAMES.DETAILS}${queryParams}`,
      { shallow: true }
    );
  } else {
    action(
      `/[BWID]/${STEP_NAMES.RESERVATIONS}/[reservationId]/${STEP_NAMES.DETAILS}${queryParams}`,
      `/${getBWID()}/${STEP_NAMES.RESERVATIONS}/${reservationId}/${
        STEP_NAMES.DETAILS
      }${queryParams}`,
      { shallow: true }
    );
  }
};

const goToQuestions = (reservationId?: string, replace?: boolean) => {
  if (!reservationId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;

  const queryParams = getQueryParamsWithFromType();

  action(
    `/[BWID]/${STEP_NAMES.RESERVATIONS}/[reservationId]/${STEP_NAMES.CONFIRMATION}${queryParams}`,
    `/${getBWID()}/${STEP_NAMES.RESERVATIONS}/${reservationId}/${
      STEP_NAMES.CONFIRMATION
    }${queryParams}`,
    { shallow: true }
  );
};

const goToBooking = (bookingId?: string, replace?: boolean) => {
  if (!bookingId) {
    return;
  }

  const action = replace ? Router.replace : Router.push;

  const queryParams = getQueryParamsWithFromType(["followUpId"]);

  action(
    `/[BWID]/${STEP_NAMES.BOOKING}/[bookingId]${queryParams}`,
    `/${getBWID()}/${STEP_NAMES.BOOKING}/${bookingId}${queryParams}`,
    { shallow: true }
  );
};

const goToCancellation = (bookingId?: string, replace?: boolean) => {
  const action = replace ? Router.replace : Router.push;

  const queryParams = getQueryParamsWithFromType();

  action(
    `/[BWID]/${STEP_NAMES.BOOKING}/[bookingId]/${STEP_NAMES.CANCELLATION}${queryParams}`,
    `/${getBWID()}/${STEP_NAMES.BOOKING}/${bookingId}/${
      STEP_NAMES.CANCELLATION
    }${queryParams}`,
    {
      shallow: true,
    }
  );
};

const goToBookingProfile = (bookingId?: string, replace?: boolean) => {
  const action = replace ? Router.replace : Router.push;

  const queryParams = getQueryParamsWithFromType();

  action(
    `/[BWID]/${STEP_NAMES.BOOKING}/[bookingId]/${STEP_NAMES.PROFILE}${queryParams}`,
    `/${getBWID()}/${STEP_NAMES.BOOKING}/${bookingId}/${
      STEP_NAMES.PROFILE
    }${queryParams}`,
    {
      shallow: true,
    }
  );
};

export const goToError = (replace?: boolean, removeQueries?: string[]) => {
  const action = replace ? Router.replace : Router.push;

  const queryParams = persistQueryParams(Router, removeQueries);

  action(
    `/[BWID]/${STEP_NAMES.ERROR}${queryParams}`,
    `/${getBWID()}/${STEP_NAMES.ERROR}${queryParams}`,
    {
      shallow: true,
    }
  );
};

const goToLiveChatProducts = (replace?: boolean) => {
  const action = replace ? Router.replace : Router.push;
  const chooseTypeInUrl = buildChooseTypeUrl();

  action(
    `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.LIVE_CHAT_PRODUCTS}`,
    `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.LIVE_CHAT_PRODUCTS}`,
    {
      shallow: true,
    }
  );
};

const goToProductsBeforeStore = (replace?: boolean) => {
  const action = replace ? Router.replace : Router.push;
  const chooseTypeInUrl = buildChooseTypeUrl();

  action(
    `/[BWID]${chooseTypeInUrl}/${STEP_NAMES.PRODUCTS}`,
    `/${getBWID()}${chooseTypeInUrl}/${STEP_NAMES.PRODUCTS}`,
    {
      shallow: true,
    }
  );
};

const goToVirtualProductsBeforeStore = (replace?: boolean) => {
  const action = replace ? Router.replace : Router.push;
  const virtualProductsBaseUrl = buildVirtualProductsBaseUrl();

  action(
    `/[BWID]${virtualProductsBaseUrl}`,
    `/${getBWID()}${virtualProductsBaseUrl}`,
    {
      shallow: true,
    }
  );
};

const goToStoreBooker = (venueId: string) => {
  const action = Router.push;

  const queryParams = persistQueryParams(Router);

  action(
    `/[BWID]/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.PRODUCTS}${queryParams}`,
    `/${getBWID()}/${STEP_NAMES.VENUES}/${venueId}/${
      STEP_NAMES.PRODUCTS
    }${queryParams}`,
    {
      shallow: true,
    }
  );
};

const goToFirstPage = (
  firstPage: StepNames,
  venueId?: string,
  queueId?: string,
  productId?: string,
  journeyFrom?: string
) => {
  if (journeyFrom) {
    Router.query.from = journeyFrom;

    if (Router.query.from?.includes(STEP_NAMES.TYPE)) {
      return PageRouter.goToChooseBookingType();
    }

    if (Router.query.from?.includes(STEP_NAMES.VENUES)) {
      const queryFromSplit = (Router.query.from as string).split("/");
      const venueIdFromQuery =
        queryFromSplit[
          queryFromSplit.findIndex((val) => val === STEP_NAMES.VENUES) + 1
        ];

      return PageRouter.goToStoreBooker(venueIdFromQuery);
    }

    switch (Router.query.from) {
      case JourneyFromType.PRODUCTS_FIRST:
        PageRouter.goToProductsBeforeStore();
        break;
      case JourneyFromType.VIRTUAL_AGGREGATED:
        PageRouter.goToVirtualProductsBeforeStore();
        break;
      default:
        PageRouter.goToVenues();
        break;
    }
    return;
  }

  switch (firstPage) {
    case STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE:
      PageRouter.goToVirtualProductsBeforeStore();
      break;
    case STEP_NAMES.PRODUCTS_BEFORE_STORE:
      PageRouter.goToProductsBeforeStore();
      break;
    case STEP_NAMES.VENUES:
      PageRouter.goToVenues();
      break;
    case STEP_NAMES.PRODUCTS:
      PageRouter.goToProducts(venueId, queueId);
      break;
    case STEP_NAMES.SLOTS:
      PageRouter.goToSlots(undefined, venueId, queueId, productId);
      break;
    case STEP_NAMES.TYPE:
      PageRouter.goToChooseBookingType();
      break;
    default:
      PageRouter.goToVenues();
      break;
  }
};

const goToBookFollowUp = (
  bookingId: string,
  replace?: boolean,
  journeyFrom?: string
) => {
  const action = replace ? Router.replace : Router.push;

  if (!Router.query?.from) {
    Router.query.from = journeyFrom;
  }

  const queryParams = persistQueryParams(Router, ["followUpId"]);
  const virtualProductsBaseUrl = buildVirtualProductsBaseUrl();
  const chooseTypeInUrl = buildChooseTypeUrl();
  let allQueryParams: string;

  if (queryParams) {
    allQueryParams = queryParams + `&followUpId=${bookingId}`;
  } else {
    allQueryParams = `?followUpId=${bookingId}`;
  }

  if (Router.query.from?.includes("type") || journeyFrom?.includes("type")) {
    action(
      `/[BWID]${chooseTypeInUrl}${allQueryParams}`,
      `/${getBWID()}${chooseTypeInUrl}${allQueryParams}`,
      {
        shallow: false,
      }
    );
    return;
  }

  if (
    Router.query.from?.includes("venues") ||
    journeyFrom?.includes("venues")
  ) {
    const queryFromSplit = ((Router.query.from || journeyFrom) as string).split(
      "/"
    );
    const venueId =
      queryFromSplit[queryFromSplit.findIndex((val) => val === "venues") + 1];

    action(
      `/[BWID]/${STEP_NAMES.VENUES}/[venueId]/${STEP_NAMES.PRODUCTS}${allQueryParams}`,
      `/${getBWID()}/${STEP_NAMES.VENUES}/${venueId}/${
        STEP_NAMES.PRODUCTS
      }${allQueryParams}`,
      {
        shallow: false,
      }
    );
  }
  switch (Router.query?.from ? Router.query?.from : journeyFrom) {
    case JourneyFromType.PRODUCTS_FIRST:
      action(
        `/[BWID]/${STEP_NAMES.PRODUCTS}${allQueryParams}`,
        `/${getBWID()}/${STEP_NAMES.PRODUCTS}${allQueryParams}`,
        {
          shallow: false,
        }
      );
      break;
    case JourneyFromType.VIRTUAL_AGGREGATED:
      action(
        `/[BWID]${virtualProductsBaseUrl}${allQueryParams}`,
        `/${getBWID()}${virtualProductsBaseUrl}${allQueryParams}`,
        {
          shallow: false,
        }
      );
      break;
    default:
      action(
        `/[BWID]/${STEP_NAMES.VENUES}${allQueryParams}`,
        `/${getBWID()}/${STEP_NAMES.VENUES}${allQueryParams}`,
        {
          shallow: false,
        }
      );
      break;
  }
};

export const PageRouter = {
  goToAddOns,
  goToAdvisors,
  goToBooking,
  goToBookingProfile,
  goToReschedule,
  goToCancellation,
  goToChooseBookingType,
  goToDetails,
  goToError,
  goToFirstPage,
  goToGenericRoute,
  goToProducts,
  goToQuestions,
  goToQueues,
  goToSlots,
  goToVenues,
  goToBookFollowUp,
  goToAddOnsBeforeStore,
  goToProductsBeforeStore,
  goToLiveChatProducts,
  goToVenuesProductsBeforeStore,
  goToVirtualAddOnsBeforeStore,
  goToVirtualProductsBeforeStore,
  goToVirtualSlotsBeforeStore,
  goToStoreBooker,
  isLiveJourney,
};

export default PageRouter;
