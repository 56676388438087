import _ from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTracking } from "../../utils/useTrackingHook";
import styled from "styled-components";
import { AdvisorSummary } from "../../modules/summary/components/advisorSummary";
import { BookingTypeSummary } from "../../modules/summary/components/bookingTypeSummary";
import { DateSummary } from "../../modules/summary/components/dateSummary";
import { ProductSummary } from "../../modules/summary/components/productSummary";
import { VenueSummary } from "../../modules/summary/components/venueSummary";
import { VirtualAppointmentInfoSummary } from "../../modules/summary/components/virtualAppointmentInfoSummary";
import {
  Advisor,
  AppHeading2,
  Coordinates,
  DistanceUnit,
  Questions,
  Reservation,
  Service,
  Settings,
  STEP_NAMES,
  StepNames,
  Theme,
  Venue,
  Booking,
  BookingType,
} from "../../shared";
import { handleRouterChange } from "../../shared/hooks/handleRouteChangeHook";
import { getColorContrast, withTheme } from "../../styles/theme/themeHelper";
import { useGenerateSteps } from "../../utils/breadcrumbsGenerator";
import { getLastRouterParamName } from "../../utils/getLastRouterParamName";
import PageRouter, {
  getBookingTypeFromUrl,
  isProductsBeforeStoreStep,
  isVirtualProductsBeforeStoreStep,
  isLiveProductsStep,
} from "../../utils/pageRouter";
import {
  onAdvisorProfilePageButtonConfirmationEvent,
  onEditButtonEvent,
} from "../analytics/eventCreators";
import { CardBookingValidTypes } from "../bookingTypes/types";
import moment from "moment";
import { useSelector } from "react-redux";
import { getBookingType } from "./selectors";

export const SUMMARY_WIDTH = 350;
export const SUMMARY_ITEM_WIDTH = 280;

const StyledContainer = styled(
  ({ isBooking, isVirtualAppointment, ...props }) => <div {...props} />
)<{
  isBooking: boolean;
}>`
  height: ${({ isBooking }) => (isBooking ? "" : "100%")};
  padding: ${({ isBooking }) => (isBooking ? "" : "15px")};
  padding-top: 0;
  padding-left: ${(props) => (props.isBooking ? "0px" : "1.3rem")};
  width: 100%;
  border-left: solid
    ${(props) => (props.theme.isMobile || props.isBooking ? "0px" : "1px")}
    ${(props) => getColorContrast(props.theme.body.backgroundColor)};
  margin-bottom: ${({ isBooking }) => (isBooking ? "10" : "0")}px;
  ${(props) => (props.theme.isMobile ? "padding-left: 15px;" : "")}
  ${(props) => (props.isBooking ? "padding-left: 0px;" : "")}

  #summary-title {
    line-height: 30px;
    top: -3px;
    position: relative;
    padding-bottom: 10px;
  }
`;

const Container = styled((props) => <div {...props} />)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.theme.isMobile ? "column" : "row")};
  margin: 0px -15px 0px 0px;
`;

const Item = styled((props) => <div {...props} />)<{ fullSize?: boolean }>`
  margin: 0px 15px 15px 0px;
  overflow: hidden;
  width: ${(props) =>
    props.fullSize || props.theme.isMobile ? "auto" : SUMMARY_WIDTH + "px"};
`;

interface SummaryLayoutProps {
  advisor: Advisor | void;
  bookingDate: string | void;
  booking?: Booking;
  currentLayoutType: string;
  hasSingleProduct: boolean;
  firstPage: StepNames;
  isLoadingBooking: boolean;
  isLoadingReservation: boolean;
  isLoadingProduct?: boolean;
  isLoadingProducts?: boolean;
  isLoadingVenue?: boolean;
  product: Service;
  questions: Questions[];
  reservation: Reservation | void;
  selectedAddOns: Service[] | void;
  settings: Settings;
  setProductsLayout: (layoutType: string) => void;
  setBookingType: (bookingType: CardBookingValidTypes) => void;
  venue: Venue;
  isVirtualAppointment?: boolean;
  onlyVirtualProducts?: boolean;
  clearProduct: () => void;
  clearCoordinates: () => void;
  clearAddOn: () => void;
  storedQueueId?: string;
  language?: string;
  userCoordinates?: Coordinates;
  distanceUnit: DistanceUnit;
  clearVenue: () => void;
  clearAll: () => void;
  selectedBookingType?: CardBookingValidTypes;
  selectedProductHasAddons?: boolean;
  isLiveChat?: boolean;
}

type Props = SummaryLayoutProps & { theme: Theme };

const SummaryLayoutBase: React.FunctionComponent<Props> = ({
  theme,
  advisor,
  bookingDate,
  booking,
  currentLayoutType,
  hasSingleProduct,
  firstPage,
  isLoadingBooking,
  isLoadingReservation,
  isLoadingProduct,
  isLoadingProducts,
  isLoadingVenue,
  product,
  questions,
  reservation,
  selectedAddOns,
  setProductsLayout,
  setBookingType,
  settings,
  venue,
  isVirtualAppointment,
  onlyVirtualProducts,
  clearProduct,
  clearCoordinates,
  clearAddOn,
  storedQueueId,
  language,
  userCoordinates,
  distanceUnit,
  clearVenue,
  clearAll,
  selectedBookingType,
  selectedProductHasAddons,
  isLiveChat,
}: Props) => {
  const { trackEvent } = useTracking();
  const router = useRouter();
  const { queueId, bookingId, productId } = router.query;

  const { t } = useTranslation();

  const isChangingRoute = handleRouterChange();

  const routerStepName: string[] | null =
    router && getLastRouterParamName(router);
  const stepName = routerStepName && routerStepName[1];

  const steps = useGenerateSteps(
    settings,
    questions,
    firstPage,
    router.query.from,
    router.pathname,
    isLiveChat
  );

  const currentBookingType = useSelector(getBookingType);

  const editLabel = t("edit", { defaultValue: "Edit" });

  const isVenues = stepName === STEP_NAMES.VENUES;
  const isProducts =
    stepName === STEP_NAMES.PRODUCTS ||
    stepName === STEP_NAMES.LIVE_CHAT_PRODUCTS;
  const isSlots = stepName === STEP_NAMES.SLOTS;
  const isBooking = stepName === STEP_NAMES.BOOKING;
  const isAdvisors = stepName === STEP_NAMES.ADVISORS;
  const isVirtualProductsBeforeStore = isVirtualProductsBeforeStoreStep(
    router,
    firstPage
  );
  const isProductsBeforeStore = isProductsBeforeStoreStep(router, firstPage);
  const isLiveProductsJourney =
    isLiveChat || isLiveProductsStep(router, firstPage);

  const isType = stepName === STEP_NAMES.TYPE;

  const isProductVirtual = product && product.virtual;

  const isAdvisorsAfterSlots =
    settings &&
    settings.staffOptions.showStaffSelection &&
    settings.staffOptions.showStaffSelectionAfterCalendar;

  const isVenuesDeeplink =
    router.query.from?.includes("/venues") ||
    router.query.from?.includes("/type/venues");

  const fromParamParts = (router.query.from as string)?.match(
    /\/?(venues)?\/?(\w*)\/?(products)?\/?(\w*)/
  ) as string[];

  const isAddonsDeeplink = useMemo(
    () =>
      !!(
        fromParamParts?.[4] &&
        fromParamParts?.[3] === "products" &&
        selectedProductHasAddons
      ),
    [selectedProductHasAddons, fromParamParts]
  );

  const showStaffExternalName =
    settings && settings.staffOptions.showStaffExternalName;

  useEffect(() => {
    if (!selectedBookingType) {
      const bookingTypeFromUrl = getBookingTypeFromUrl(
        router,
        firstPage,
        (booking as Booking)?.bookingType
      );
      if (bookingTypeFromUrl) {
        setBookingType(bookingTypeFromUrl);
      } else if (router.query.from?.includes("type") && currentBookingType) {
        switch (currentBookingType) {
          case BookingType.LIVE_VIDEO_CHAT:
            setBookingType("liveChat");
            return;
          case BookingType.VIRTUAL_AGGREGATED:
            setBookingType("virtualAggregated");
            return;
          case BookingType.IN_PERSON:
          case BookingType.VIRTUAL:
          default:
            setBookingType("inStoreOrVirtual");
        }
      }
    }
  }, [
    booking,
    firstPage,
    router,
    selectedBookingType,
    setBookingType,
    currentBookingType,
  ]);

  const allowRouting = (targetStep: StepNames) => {
    if (isChangingRoute || isLoadingBooking) {
      return;
    }

    if (
      firstPage &&
      _.findIndex(steps, { stepName: targetStep }) <
        _.findIndex(steps, { stepName: firstPage })
    ) {
      return;
    }

    const skipProductPageIfSingleProduct =
      !settings.showProductSelectionIfSingleProduct && hasSingleProduct;

    switch (targetStep) {
      case STEP_NAMES.TYPE:
      case STEP_NAMES.VENUES:
        if (isBooking || bookingId || isVenuesDeeplink) {
          return;
        }

        break;
      case STEP_NAMES.PRODUCTS:
        if (
          isBooking ||
          !product ||
          (product &&
            product.maxAddOnsAllowedForReservation &&
            currentLayoutType === "products") ||
          (!product.numberOfAddOns &&
            !storedQueueId &&
            skipProductPageIfSingleProduct) ||
          bookingId ||
          (isLiveProductsJourney && skipProductPageIfSingleProduct) ||
          (skipProductPageIfSingleProduct && stepName === STEP_NAMES.ADDONS) ||
          (isAddonsDeeplink && stepName === STEP_NAMES.ADDONS)
        ) {
          return;
        }

        break;
      case STEP_NAMES.SLOTS:
        if (isBooking || isSlots) {
          return;
        }

        break;
      case STEP_NAMES.ADVISORS:
        if (
          isBooking ||
          !settings.staffOptions.showStaffSelection ||
          isVirtualProductsBeforeStore
        ) {
          return;
        }

        break;
    }
    return editLabel;
  };

  const shouldHideBookingTypeSummary = isType;

  const renderBookingTypeSummary = () => {
    if (!selectedBookingType) {
      return <></>;
    }

    const onEditClick = () => {
      clearAddOn();
      clearCoordinates();
      clearProduct();
      clearVenue();
      clearAll();
      PageRouter.goToChooseBookingType();
      trackEvent(onEditButtonEvent(STEP_NAMES.TYPE));
    };

    return (
      <Item id="booking-type-summary">
        <BookingTypeSummary
          selectedBookingType={selectedBookingType}
          linkText={allowRouting(STEP_NAMES.TYPE)}
          onLinkClick={onEditClick}
        />
      </Item>
    );
  };

  const shouldHideVenueSummary =
    (!venue && !isLoadingVenue) ||
    isVenues ||
    isLoadingProducts ||
    isVirtualAppointment ||
    (!isProducts && isProductVirtual) ||
    onlyVirtualProducts;

  const renderVenueSummary = () => {
    if (shouldHideVenueSummary) {
      return <></>;
    }

    const onEditClick = () => {
      if (isLoadingVenue) {
        return;
      }

      if (!isProductsBeforeStore) {
        clearAddOn();
        clearProduct();
      }
      clearCoordinates();
      clearVenue();
      !isProductsBeforeStore
        ? PageRouter.goToVenues()
        : PageRouter.goToVenuesProductsBeforeStore(
            (productId as string) || (reservation as Reservation).productId
          );
      trackEvent(onEditButtonEvent(STEP_NAMES.VENUES));
    };

    return (
      <Item id="venue-summary">
        <VenueSummary
          venue={venue}
          linkText={allowRouting(STEP_NAMES.VENUES)}
          onLinkClick={onEditClick}
          settings={settings}
          loading={isLoadingVenue}
          userCoordinates={userCoordinates}
          distanceUnit={distanceUnit}
        />
      </Item>
    );
  };

  const shouldHideProductSummary =
    (isVenues && !isProductsBeforeStoreStep) ||
    isLoadingProducts ||
    (!product && !isLoadingProduct) ||
    (isProducts &&
      (currentLayoutType === "products" || currentLayoutType === "live"));

  const renderProductSummary = () => {
    if (shouldHideProductSummary) {
      return null;
    }

    const onEditClick = () => {
      if (isLoadingProduct) {
        return;
      }
      if (product.maxAddOnsAllowedForReservation && isProducts) {
        setProductsLayout("products");
      } else {
        trackEvent(onEditButtonEvent(STEP_NAMES.PRODUCTS));
        if (!isProducts && !isVenuesDeeplink) {
          clearProduct();
          clearAddOn();
        }
        if (isProductsBeforeStore) {
          clearAddOn();
          clearCoordinates();
          clearProduct();
          clearVenue();
          PageRouter.goToProductsBeforeStore();
          return;
        }
        if (isLiveProductsJourney) {
          clearProduct();
          clearAddOn();
          PageRouter.goToLiveChatProducts();
          return;
        }
        if (isVirtualProductsBeforeStore) {
          clearAll();
          PageRouter.goToVirtualProductsBeforeStore();
          return;
        }

        if (queueId || storedQueueId) {
          PageRouter.goToQueues(venue && venue.id);
        } else if (firstPage === STEP_NAMES.ADDONS) {
          PageRouter.goToAddOns(venue.id, queueId, product.id, true);
        } else {
          PageRouter.goToProducts(venue && venue.id);
        }
      }
    };

    return (
      <Item id="product-summary">
        <ProductSummary
          service={product}
          linkText={allowRouting(STEP_NAMES.PRODUCTS)}
          onLinkClick={onEditClick}
          selectedAddOns={selectedAddOns}
          theme={theme}
          settings={settings}
          loading={isLoadingProduct}
        />
      </Item>
    );
  };

  const shouldHideDateSummary =
    isAdvisorsAfterSlots && isAdvisors && bookingDate
      ? false
      : (!reservation && !isLoadingReservation) ||
        isBooking ||
        isVenues ||
        isProducts ||
        isSlots ||
        isLoadingReservation ||
        isLiveChat;
  const renderDateSummary = () => {
    if (shouldHideDateSummary) {
      return null;
    }

    const onEditClick = () => {
      if (isLoadingReservation) {
        return;
      }

      if (isVirtualProductsBeforeStore) {
        clearVenue();
        if (reservation) {
          PageRouter.goToVirtualSlotsBeforeStore(reservation.productId);
        } else if (product) {
          PageRouter.goToVirtualSlotsBeforeStore(product.id);
        }
      } else {
        if (reservation) {
          PageRouter.goToSlots(
            bookingId as string,
            reservation.venueId,
            storedQueueId,
            reservation.productId
          );
        } else if (venue && product) {
          PageRouter.goToSlots(
            bookingId as string,
            venue.id,
            storedQueueId,
            product.id
          );
        }
      }

      trackEvent(onEditButtonEvent(STEP_NAMES.SLOTS));
    };

    return (
      <Item id="date-summary">
        <DateSummary
          date={reservation ? reservation.startDateTime : bookingDate || ""}
          linkText={allowRouting(STEP_NAMES.SLOTS)}
          onLinkClick={onEditClick}
          timezone={
            isVirtualProductsBeforeStore
              ? moment.tz.guess()
              : venue && venue.timezone
          }
          loading={isLoadingReservation || !venue}
        />
      </Item>
    );
  };

  const shouldHideAdvisorSummary =
    !product || !advisor || !venue || isVenues || isProducts || isAdvisors;

  const renderAdvisorSummary = () => {
    if (shouldHideAdvisorSummary || !advisor) {
      return null;
    }

    const allowRoutingToProfile =
      isBooking &&
      settings &&
      settings.staffOptions &&
      settings.staffOptions.showStaffProfileConfirmation;

    const onEditClick = () => {
      clearProduct();
      if (allowRoutingToProfile) {
        PageRouter.goToBookingProfile(bookingId as string);
        trackEvent(onAdvisorProfilePageButtonConfirmationEvent());
      } else {
        PageRouter.goToAdvisors(
          bookingId as string,
          venue.id,
          storedQueueId,
          product.id
        );
        trackEvent(onEditButtonEvent(STEP_NAMES.ADVISORS));
      }
    };

    const linkText = allowRoutingToProfile
      ? t("profilePage", { defaultValue: "Profile Page" })
      : allowRouting(STEP_NAMES.ADVISORS);

    return (
      <Item id="advisor-summary">
        <AdvisorSummary
          advisor={advisor}
          linkText={linkText}
          language={language || "en"}
          showStaffExternalName={showStaffExternalName}
          onLinkClick={onEditClick}
        />
      </Item>
    );
  };

  const shouldHideVirtualAppointmentInfoSummary =
    !isBooking || (!onlyVirtualProducts && !isProductVirtual);
  const renderVirtualAppointmentInfoSummary = () => {
    if (shouldHideVirtualAppointmentInfoSummary) {
      return null;
    }

    return (
      <Item fullSize={isBooking} id="advisor-summary">
        <VirtualAppointmentInfoSummary />
      </Item>
    );
  };

  const renderSummaryInfo = () => {
    return (
      <Container className="summary-info">
        {renderBookingTypeSummary()}
        {renderProductSummary()}
        {renderDateSummary()}
        {renderAdvisorSummary()}
        {renderVenueSummary()}
        {renderVirtualAppointmentInfoSummary()}
      </Container>
    );
  };

  const isLiveVideoProductsPageAndFirstPage = [stepName, firstPage].every(
    (step) => step === STEP_NAMES.LIVE_CHAT_PRODUCTS
  );

  const shouldHideSummary =
    (shouldHideVenueSummary &&
      shouldHideProductSummary &&
      shouldHideDateSummary &&
      shouldHideAdvisorSummary &&
      shouldHideVirtualAppointmentInfoSummary &&
      shouldHideBookingTypeSummary) ||
    isLiveVideoProductsPageAndFirstPage;

  return shouldHideSummary ? (
    <></>
  ) : (
    <StyledContainer isBooking={isBooking}>
      <AppHeading2 id={"summary-title"}>
        {t("summary", { defaultValue: "Summary" })}
      </AppHeading2>
      {renderSummaryInfo()}
    </StyledContainer>
  );
};

export const SummaryLayout = withTheme(SummaryLayoutBase);
