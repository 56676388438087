import { Theme } from "../../shared";

// tslint:disable:object-literal-sort-keys
export class ThemeFixture {
  private settings: Theme = {
    logo: "/static/images/logo-qudini-white.svg",
    header: {
      backgroundColor: "#B8222F"
    },
    body: {
      backgroundColor: "#FFF",
      font: {
        family: "Ubuntu",
        mainTextColor: "#0A1433",
        secondaryTextColor: "#808080",
        tertiaryTextColor: "#DBDBDB"
      },
      buttons: {
        mainBackgroundColor: "#3DA5D9",
        mainTextColor: "#FFF",
        secondaryBackgroundColor: "#7FB069",
        secondaryTextColor: "#FFF",
        productButtonColor: "#FFF",
        productIconTextColor: "#0A1433",
        productSecondaryTextColor: "#9b9b9b"
      }
    }
  };

  private settingsNotAvailable: Theme = {
    logo: undefined,
    header: {
      backgroundColor: "#ecf0f1"
    },
    body: {
      backgroundColor: "#FFF",
      font: {
        family: "Ubuntu",
        mainTextColor: "#0A1433",
        secondaryTextColor: "#808080",
        tertiaryTextColor: "#DBDBDB"
      },
      buttons: {
        mainBackgroundColor: "#3DA5D9",
        mainTextColor: "#FFF",
        secondaryBackgroundColor: "#7FB069",
        secondaryTextColor: "#FFF",
        productButtonColor: "#FFF",
        productIconTextColor: "#0A1433",
        productSecondaryTextColor: "#9b9b9b"
      }
    }
  };

  public get withDefaultSettings() {
    return this.settings;
  }

  public get withSettingsNotAvailable() {
    return this.settingsNotAvailable;
  }
}
