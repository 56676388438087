import { useRouter } from "next/router";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { withTheme } from "styled-components";
import { ApiLanguages, Policies, Theme } from "..";
import colors from "../../styles/theme/colors";
import { getColorContrast, styled } from "../../styles/theme/themeHelper";
import { AppLink } from "./appLink";
import { AppParagraph4, AppPreFooterStyled } from "./appParagraph";
import { AppLanguageSelector } from "./appLanguageSelector";
import { AppCurrencySelector } from "./appCurrencySelector";

const StyledFooter = styled.footer`
  padding: 0.5rem;
  width: 100%;
  border-top: ${(props) => getColorContrast(props.theme.body.backgroundColor)}
    1px solid;

  @media only screen and (max-width: 767px) {
    padding: 1rem;
    min-height: 100px;
  }

  @media only screen and (min-width: 768px) {
    padding: 1.5rem 4rem 1.5rem 4rem;
    min-height: 92px;
  }
`;

const StyledCenterContent = styled.div`
  margin-bottom: 0.5rem;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

const StyledSideContent = styled.div`
  @media only screen and (min-width: 768px) {
    width: 25%;
  }
`;

const StyledFooterLogo = styled.img`
  height: 24px;
`;

const StyledFooterLanguageDiv = styled.div<{ spacing?: boolean }>`
  position: relative;
  bottom: -12px;
  ${(props) => (props.spacing ? "margin-right: 1rem;" : "")}
`;

export interface AppFooterProps {
  policies?: Policies | void;
  showLogo?: boolean;
  preFooter?: string;
  showCart?: boolean;
  availableLanguages?: ApiLanguages | void;
  currentLanguage?: string;
  onLanguageSelection?: (language: string) => void;
  showLanguageSelector?: boolean;
  showCurrencySelector?: boolean;
  theme: Theme;
}

type Props = AppFooterProps & { theme: Theme };

const AppFooterBase: React.FunctionComponent<Props> = ({
  policies,
  showLogo,
  preFooter,
  showCart,
  availableLanguages,
  currentLanguage,
  onLanguageSelection,
  showLanguageSelector,
  showCurrencySelector,
  theme,
}: Props) => {
  const router = useRouter();
  const [isEmbedded, setIsEmbedded] = React.useState(false);

  React.useEffect(() => {
    setIsEmbedded(router && String(router.query.embedded) === "true");
  }, []);

  const { t } = useTranslation();

  const genFooterLink = (id: string, link: string, text: string) => (
    <AppLink
      id={id}
      fontWeight={400}
      key={`${id}-key`}
      href={link}
      target={"_blank"}
      fontSize={11}
      color={colors.primary}
    >
      {text}
    </AppLink>
  );

  const footerContents = () => {
    if (!policies) {
      return null;
    }

    const policiesList = [];

    if (policies.cookiePolicy && policies.cookiePolicy.url) {
      policiesList.push(
        genFooterLink(
          "footer-cookie-policy",
          policies.cookiePolicy.url,
          t("cookiePolicy", { defaultValue: "Cookie Policy" })
        )
      );
    }

    if (policies.privacyPolicy && policies.privacyPolicy.url) {
      policiesList.push(
        genFooterLink(
          "footer-privacy-policy",
          policies.privacyPolicy.url,
          t("privacyPolicy", { defaultValue: "Privacy Policy" })
        )
      );
    }

    if (
      policies.termsAndConditionsPolicy &&
      policies.termsAndConditionsPolicy.url
    ) {
      policiesList.push(
        genFooterLink(
          "footer-terms-conditions",
          policies.termsAndConditionsPolicy.url,
          t("termsConditions", { defaultValue: "Terms & Conditions" })
        )
      );
    }

    const separator = (key: string) => (
      <AppParagraph4 className={"font-regular color-text1"} key={"|" + key}>
        &nbsp;|&nbsp;
      </AppParagraph4>
    );

    return policiesList.reduce((total, current, index) => {
      if (index !== 0 && index < policiesList.length) {
        total.push(separator(index.toString()));
      }
      total.push(current);

      return total;
    }, [] as React.ReactChild[]);
  };

  const footerLogo = showLogo && (
    <div className={"float-right"}>
      <a href="https://www.verint.com/retail-choreography/">
        <StyledFooterLogo
          id="footer-brand-logo"
          // alt="Qudini brand logo"
          alt="Go to Qudini customer page"
          src={"/static/images/logo-qudini-red.svg"}
        />
      </a>
    </div>
  );

  const footerSocial = <div />;

  const availableLanguagesStrings =
    availableLanguages &&
    availableLanguages.languages.map(({ isoCode }) => isoCode);

  const hideLanguageDropdown = !(
    availableLanguagesStrings ||
    currentLanguage ||
    onLanguageSelection ||
    showLanguageSelector
  );

  const fontColor = isEmbedded
    ? theme.body.font.mainTextColor
    : getColorContrast(theme.header.backgroundColor, 1, 5);

  const backgroundColor = isEmbedded
    ? theme.body.backgroundColor
    : getColorContrast(theme.header.backgroundColor, 1, 0.2);

  const languageDropdown = (
    <AppLanguageSelector
      outline={isEmbedded}
      availableLanguages={availableLanguagesStrings}
      currentLanguage={currentLanguage}
      onLanguageSelection={onLanguageSelection}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      hide={hideLanguageDropdown}
    />
  );

  const currencyDropdown = (
    <div className={"float-right"}>
      <AppCurrencySelector
        outline={isEmbedded}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
      />
    </div>
  );

  const mobileFooterWithCartAndCurrencyAndLanguage = showCart &&
    showCurrencySelector &&
    showLanguageSelector && (
      <>
        <StyledCenterContent className={"d-flex order-0 order-sm-1 ml-auto"}>
          {footerContents()}
        </StyledCenterContent>
        <StyledSideContent className="order-3 ml-auto">
          {footerLogo}
        </StyledSideContent>
        <StyledFooterLanguageDiv
          spacing
          className="order-sm-2 order-md-0 margin-right"
        >
          {currencyDropdown}
        </StyledFooterLanguageDiv>
        <StyledFooterLanguageDiv className="order-sm-2 order-md-0">
          {languageDropdown}
        </StyledFooterLanguageDiv>
      </>
    );

  const mobileFooterWithCurrencyAndLanguage = ((!showCart &&
    showCurrencySelector) ||
    (showCart && !showCurrencySelector)) &&
    showLanguageSelector && (
      <>
        <StyledCenterContent className={"d-flex order-0 order-sm-1 ml-auto"}>
          {footerContents()}
        </StyledCenterContent>
        <StyledSideContent className="order-3 ml-auto">
          {footerLogo}
        </StyledSideContent>
        <StyledFooterLanguageDiv className="order-sm-2 order-md-0">
          {languageDropdown}
        </StyledFooterLanguageDiv>
      </>
    );

  const mobileFooterWithCartAndCurrency = showCart &&
    showCurrencySelector &&
    !showLanguageSelector && (
      <>
        <StyledCenterContent className={"d-flex order-0 order-sm-1 ml-auto"}>
          {footerContents()}
        </StyledCenterContent>
        <StyledSideContent className="order-3 ml-auto">
          {footerLogo}
        </StyledSideContent>
        <StyledFooterLanguageDiv className="order-sm-2 order-md-0">
          {currencyDropdown}
        </StyledFooterLanguageDiv>
      </>
    );

  const desktopFooter = (
    <>
      <StyledCenterContent
        className={"d-flex order-0 order-sm-1 mr-auto ml-auto"}
      >
        {footerContents()}
      </StyledCenterContent>
      <StyledSideContent className="order-2 order-sm-0 mr-auto">
        {footerSocial}
      </StyledSideContent>
      <StyledSideContent className="order-3 ml-auto">
        {footerLogo}
      </StyledSideContent>
    </>
  );

  const noCartFooter =
    !showCart &&
    (!showCurrencySelector || !showLanguageSelector) &&
    desktopFooter;

  return isEmbedded ? (
    <></>
  ) : (
    <StyledFooter id="app-footer">
      {preFooter && (
        <div className={"container d-flex flex-row flex-wrap space-between"}>
          <StyledCenterContent
            className={"d-flex order-0 order-sm-1 mr-auto ml-auto"}
          >
            <AppPreFooterStyled
              id="pre-footer"
              className={"font-regular color-text1"}
            >
              {preFooter}
            </AppPreFooterStyled>
          </StyledCenterContent>
        </div>
      )}

      <div className={"container d-flex flex-row flex-wrap space-between"}>
        {theme.isMobile ? (
          <>
            {mobileFooterWithCartAndCurrencyAndLanguage}
            {mobileFooterWithCurrencyAndLanguage}
            {mobileFooterWithCartAndCurrency}
            {noCartFooter}
          </>
        ) : (
          desktopFooter
        )}
      </div>
    </StyledFooter>
  );
};

export const AppFooter = withTheme(AppFooterBase);
