export interface AddressLinesFilter {
  displayStreet: boolean;
  displayCity: boolean;
  displayRegion: boolean;
  displayCountry: boolean;
  displayPostcode: boolean;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface StaffOptions {
  showStaffSelection: boolean;
  showStaffSelectionAfterCalendar: boolean;
  showStaffDescriptions: boolean;
  showStaffExternalName: boolean;
  showStaffExternalLink: boolean;
  showStaffNoPreferenceOption: boolean;
  showStaffOnQueues: boolean;
  showStaffProfileConfirmation: boolean;
  showStaffSelectionInCalendar: boolean;
}

export interface StoreSelectionSettings {
  url: string;
  buttonName: string;
}

export enum CustomerDetailsOptions {
  DO_NOT_SHOW = "DO_NOT_SHOW",
  SHOW_AS_MANDATORY = "SHOW_AS_MANDATORY",
  SHOW_AS_NOT_MANDATORY = "SHOW_AS_NOT_MANDATORY",
}

export interface MandatoryCustomerDetailsSettings {
  firstName: keyof typeof CustomerDetailsOptions;
  lastName: keyof typeof CustomerDetailsOptions;
  email: keyof typeof CustomerDetailsOptions;
  phoneNumber: keyof typeof CustomerDetailsOptions;
  postcode: keyof typeof CustomerDetailsOptions;
  groupSize: keyof typeof CustomerDetailsOptions;
}

export enum ProductDisplayOptions {
  BLANK = "BLANK",
  ICON = "ICON",
  IMAGE = "IMAGE",
}

export enum DistanceUnit {
  MILES = "MILES",
  KMS = "KM",
}

export enum TimeSlotDisplayOption {
  GROUPED = "GROUPED",
  NO_GROUPING = "NO_GROUPING",
}

export enum VirtualAppointmentStorePathwayOptions {
  VENUE_FINDER = "VENUE_FINDER",
  SERVICE_SELECT = "SERVICE_SELECT",
}

export interface Settings {
  enableScrollToNextAvailableSlot: boolean;
  homeURL: string;
  maxDaysForBookingInAdvance: number;
  maxWeeksForBookingInAdvance: number;
  minMinutesForBookingInAdvance: number;
  defaultDate: string;
  addressLinesFilter: AddressLinesFilter;
  defaultCurrencyCode: string;
  defaultGeoLocation: Coordinates;
  googleMaps: boolean;
  headerScript?: string;
  footerScript?: string;
  searchCountryCodes: string;
  showNextAvailableBookingTime: boolean;
  showAlternativeStores?: boolean;
  showProductSelectionIfSingleProduct: boolean;
  showBookingConfirmationIcons: boolean;
  showProductsInListView: boolean;
  showQueueSelection: boolean;
  showSummaryMobileView: boolean;
  staffOptions: StaffOptions;
  useCustomStoreSelection: boolean;
  storeSelection: StoreSelectionSettings;
  mandatoryCustomerDetails: MandatoryCustomerDetailsSettings;
  mandatoryVirtualAppointmentCustomerDetails: MandatoryCustomerDetailsSettings;
  mandatoryLiveVideoChatAppointmentCustomerDetails: MandatoryCustomerDetailsSettings;
  showCustomerQuestions: boolean;
  enableSms: boolean;
  enableCaptcha: boolean;
  sso: boolean;
  samlcontentReadOnly: boolean;
  showCurrencySelector: boolean;
  showCustomerTitle: boolean;
  showAttributionQuestions: boolean;
  showQudiniBrand: boolean;
  showServiceDuration?: boolean;
  showServicePricing?: boolean;
  showNotes: boolean;
  productDisplay: ProductDisplayOptions;
  enableStaffSearch: boolean;
  showStaffOnlyOnSearch: boolean;
  distanceUnit?: DistanceUnit;
  enableProductCheckboxConfirmation: boolean;
  enableViewEditWhenJoiningQuestionnaire: boolean;
  enableAfterJoiningQuestionnaire: boolean;
  alternativeAddressLinesFilter: AddressLinesFilter;
  storeAppointmentTimeSlotDisplay: TimeSlotDisplayOption;
  virtualAppointmentTimeSlotDisplay: TimeSlotDisplayOption;
  notAfterVirtualAppointmentValue: number;
  combineCustomerDetailsAndYourVisitQuestions: boolean;
  virtualAppointmentStorePathway: VirtualAppointmentStorePathwayOptions;
  identityProviderId?: string | null;
  liveVideoChatEstimatedWaitTime: boolean;
}

// For backward compatibility support
export const shouldShowServiceDuration = (settings?: Settings): boolean =>
  !!settings &&
  Boolean(
    !settings.hasOwnProperty("showServiceDuration") ||
      settings.showServiceDuration
  );

export const shouldShowServiceEstimatedWaitTime = (
  settings?: Settings
): boolean =>
  !!settings &&
  Boolean(
    !settings.hasOwnProperty("liveVideoChatEstimatedWaitTime") ||
      settings.liveVideoChatEstimatedWaitTime
  );

export const shouldShowServicePricing = (settings?: Settings): boolean =>
  !!settings &&
  Boolean(
    !settings.hasOwnProperty("showServicePricing") ||
      settings.showServicePricing
  );

export const getDistanceUnit = (settings?: Settings | void): DistanceUnit =>
  !!settings && settings.hasOwnProperty("distanceUnit") && settings.distanceUnit
    ? settings.distanceUnit
    : DistanceUnit.MILES;

export const getStoreAppointmentTimeSlotDisplayOption = (
  settings?: Settings | void
): TimeSlotDisplayOption =>
  !!settings &&
  settings.hasOwnProperty("storeAppointmentTimeSlotDisplay") &&
  settings.storeAppointmentTimeSlotDisplay
    ? settings.storeAppointmentTimeSlotDisplay
    : TimeSlotDisplayOption.GROUPED;

export const getVirtualAppointmentTimeSlotDisplayOption = (
  settings?: Settings | void
): TimeSlotDisplayOption =>
  !!settings &&
  settings.hasOwnProperty("virtualAppointmentTimeSlotDisplay") &&
  settings.virtualAppointmentTimeSlotDisplay
    ? settings.virtualAppointmentTimeSlotDisplay
    : TimeSlotDisplayOption.GROUPED;
